.Footer-module_footer_smP7p__Main {
  background-color: var(--bg-block);
  width: 100%;
}

.Footer-module_footer__container_gjEa-__Main {
  max-width: 1280px;
  margin: 0 auto;
}

.Footer-module_footer__container--top_5wb74__Main {
  padding: 40px;
  position: relative;
}

.Footer-module_footer__bottom_PblvC__Main, .Footer-module_footer__center_xQc7c__Main {
  padding: 40px;
}

.Footer-module_footer__top_fR6nT__Main {
  background-color: var(--bg-block-gray);
  z-index: 0;
  width: 100%;
  position: relative;
}

.Footer-module_footer__center_xQc7c__Main {
  justify-content: space-between;
  display: flex;
}

.Footer-module_footer__bottom_PblvC__Main {
  border-top: 1px solid var(--line-line);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Footer-module_footer__bg_HC1Ix__Main {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.Footer-module_footer__apps_4xjMh__Main, .Footer-module_footer__social-media_xjonP__Main {
  gap: 10px;
  display: flex;
}

.Footer-module_footer__store-icon_l75oW__Main {
  cursor: pointer;
}

.Footer-module_footer__store-icon__text_E2wld__Main {
  color: var(--text-white) !important;
}

.Footer-module_footer__store-icon_l75oW__Main svg {
  fill: var(--icons-white);
}

.Footer-module_footer__categories_RJlIz__Main {
  gap: 20px;
  display: flex;
}

.Footer-module_footer__link_uHHqm__Main {
  white-space: nowrap;
  color: var(--text-white-opacity-60);
  cursor: pointer;
}

.Footer-module_footer__link_uHHqm__Main:hover, .Footer-module_footer__link_uHHqm__Main:active {
  color: var(--text-link-active);
}

.Footer-module_footer__be-aware_xPHRb__Main path {
  fill: var(--icons-primary);
}

.Footer-module_footer__icon-container_KpbUJ__Main {
  justify-content: space-between;
  margin-bottom: 40px;
  display: flex;
}

.Footer-module_footer__icon_kciAh__Main {
  background-color: var(--bg-white-opacity-5);
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  max-height: 50px;
  padding: 15px;
  display: flex;
}

.Footer-module_footer__icon_kciAh__Main svg {
  fill: var(--icons-white);
}

.Footer-module_footer__copyright-first-part_G1oAG__Main {
  margin-right: 15px;
}

.Footer-module_footer__copyright_HVYbN__Main {
  color: var(--text-primary);
  white-space: nowrap;
}

.Footer-module_footer__privacy-text_tZyZe__Main {
  color: var(--text-link-active);
}

.Footer-module_footer__bottom-right-column_jxFpE__Main {
  align-items: center;
  gap: 20px;
  display: flex;
}

.Footer-module_footer__gpwa_g9DCL__Main {
  cursor: pointer;
}

.Footer-module_footer__bottom-container_CZ6xY__Main {
  align-items: center;
  gap: 20px;
  display: flex;
}

.Footer-module_footer__disclaimer_AT61k__Main {
  color: var(--text-secondary);
}

@media (max-width: 1024px) {
  .Footer-module_footer__top_fR6nT__Main {
    gap: 20px;
  }

  .Footer-module_footer__container--top_5wb74__Main {
    padding: 20px;
  }

  .Footer-module_footer__bottom_PblvC__Main, .Footer-module_footer__center_xQc7c__Main {
    padding: 40px 20px;
  }

  .Footer-module_footer__icon-container_KpbUJ__Main {
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .Footer-module_footer__categories_RJlIz__Main {
    width: 100%;
    display: flex;
  }

  .Footer-module_footer__apps_4xjMh__Main, .Footer-module_footer__social-media_xjonP__Main {
    justify-content: center;
  }

  .Footer-module_footer__social-media_xjonP__Main {
    gap: 10px;
  }
}

@media (max-width: 660px) {
  .Footer-module_footer__bottom_PblvC__Main {
    display: block;
  }

  .Footer-module_footer__copyright_HVYbN__Main {
    margin-bottom: 40px;
  }

  .Footer-module_footer__categories_RJlIz__Main {
    flex-wrap: wrap;
    display: inline-flex;
  }

  .Footer-module_footer__icon-container_KpbUJ__Main, .Footer-module_footer__center_xQc7c__Main {
    flex-direction: column;
    gap: 20px;
  }

  .Footer-module_footer__social-media_xjonP__Main {
    margin-bottom: 20px;
  }

  .Footer-module_footer__bottom-container_CZ6xY__Main {
    flex-direction: column;
    gap: 40px;
    display: flex;
  }
}

@media (max-width: 360px) {
  .Footer-module_footer__apps_4xjMh__Main, .Footer-module_footer__social-media_xjonP__Main {
    gap: 7px;
  }
}

.FooterSpoiler-module_spoiler_vRHJ9__Main {
  background-color: var(--bg-block);
  width: 100%;
  max-width: 270px;
  position: relative;
}

.FooterSpoiler-module_spoiler__wrapper_bw6xI__Main {
  cursor: pointer;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
}

.FooterSpoiler-module_spoiler__name_SgHBn__Main {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--text-primary);
  margin-right: 5px;
  overflow: hidden;
}

.FooterSpoiler-module_spoiler__arrow-icon-wrapper_oXdtC__Main {
  align-items: center;
  display: flex;
}

.FooterSpoiler-module_spoiler__arrow-icon-wrapper__icon_7Qvun__Main {
  fill: var(--icons-primary);
}

.FooterSpoiler-module_spoiler__arrow-icon_a-7fn__Main {
  fill: var(--icons-primary);
  width: 16px;
  height: 16px;
  transition: transform .25s, fill .25s;
}

.FooterSpoiler-module_spoiler__arrow-icon--rotated_II-AS__Main {
  transform: rotate(180deg);
}

.FooterSpoiler-module_spoiler__dropdown-container_roIpF__Main {
  flex-direction: column;
  gap: 20px;
  height: 0;
  display: flex;
}

.FooterSpoiler-module_spoiler__dropdown-container--active_k-YwE__Main {
  height: auto;
}

.FooterSpoiler-module_spoiler__dropdown-item_tvxsm__Main {
  white-space: nowrap;
  color: var(--text-secondary);
  text-overflow: ellipsis;
  cursor: pointer;
  display: block;
  overflow: hidden;
}

.FooterSpoiler-module_spoiler__dropdown-item_tvxsm__Main:hover, .FooterSpoiler-module_spoiler__dropdown-item_tvxsm__Main:active {
  color: var(--text-link-active);
}

.FooterSpoiler-module_spoiler__body_06xPB__Main {
  background-color: var(--bg-block);
  border-radius: 10px;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
}

@media (max-width: 660px) {
  .FooterSpoiler-module_spoiler_vRHJ9__Main {
    max-width: none;
  }

  .FooterSpoiler-module_spoiler__dropdown-container_roIpF__Main {
    height: auto;
  }

  .FooterSpoiler-module_spoiler__dropdown-container--active_k-YwE__Main {
    height: 0;
  }

  .FooterSpoiler-module_spoiler__wrapper_bw6xI__Main {
    margin-bottom: 0;
  }

  .FooterSpoiler-module_spoiler__dropdown-item_tvxsm__Main:first-child {
    margin-top: 20px;
  }

  .FooterSpoiler-module_spoiler__arrow-icon_a-7fn__Main {
    fill: var(--icons-primary);
    width: 16px;
    height: 16px;
    transition: transform .25s, fill .25s;
    transform: rotate(180deg);
  }

  .FooterSpoiler-module_spoiler__arrow-icon--rotated_II-AS__Main {
    transform: rotate(0);
  }
}

.StoreIcons-module_store-icons_tN7OK__Main {
  background-color: var(--bg-white-opacity-5);
  border-radius: 10px;
  align-items: center;
  gap: 10px;
  width: 150px;
  height: 50px;
  padding: 20px 10px;
  display: flex;
}

.StoreIcons-module_store-icons_tN7OK__Main .StoreIcons-module_store-icon_-ZKUE__Main {
  fill: var(--icons-primary);
}

.StoreIcons-module_store-icons--download_3D6zU__Main {
  white-space: nowrap;
  color: var(--text-secondary);
}

.StoreIcons-module_store-icons--market_6K6q1__Main {
  white-space: nowrap;
  color: var(--text-primary);
}

@media (max-width: 390px) {
  .StoreIcons-module_store-icons_tN7OK__Main {
    gap: 6px;
    padding: 20px 7px;
  }
}

@media (max-width: 360px) {
  .StoreIcons-module_store-icons_tN7OK__Main {
    gap: 3px;
    padding: 20px 5px;
  }
}

